.poditem{
  background-color: rgba(255, 255, 255, 0.25);
  padding: 10px ;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 3px 5px 0px, rgba(60, 64, 67, 0.15) 0px 4px 5px 2px;
  width: 100%;
}
.podImg_box > img{
  width: 100%;
  object-fit: cover;
  height: 250px;
  border-radius: 8px;

}
.podImg_box{
  position: relative;
}
.podImg_box .playbtn{
  top: 30%;
  position: absolute;
  left: 36%;
}
.podImg_box .playbtn img{
  max-width: 80px !important;
}
.custom_card {
  border-radius: 14px!important;
  box-shadow: 0 5px 15px 0 #00000026!important
}
