
.label {
    margin-bottom: 7px !important;
    font-weight: 700 !important;
    color: #222;
    font-size: 14px !important;
}
.border{
    border: 1px solid #ddd !important;
}
.boxShadow{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
.borderR_4{
    border-radius: 4px !important;
}
.fz-10 {
    font-size: 10px !important;
}
.fz-12 {
    font-size: 12px !important;
}

.fz-13 {
    font-size: 13px !important;
}

.fz-14 {
    font-size: 14px !important;
}

.fz-16 {
    font-size: 16px !important;
}

.fz-18 {
    font-size: 18px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top:40px !important;
}
.p-20 {
    padding: 20px !important;
}

.p-10 {
    padding: 10px !important;
}
.pb-12 {
    padding-bottom: 12px !important;
}
.pt-7 {
    padding-top: 7px !important;
}
.py-20 {
    padding:20px 0 !important;
}
.m-0{
    margin: 0 !important;
}

.p-0 {
    padding: 0px !important;
}
.ml-auto {
    margin-left: auto !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.bg_secondaryColor{
    background-color: var(--secondary-color) !important;
}
.breakword{
    word-break: break-word !important;
}
.w100, .w-full {
    width: 100% !important;
}
.maxw100{
    max-width: 100% !important;
}
.minwauto {
    min-width: auto !important;
}
.minw260 {
    min-width: 260px !important;
}
.minw200 {
    min-width: 200px !important;
}
.minw80 {
    min-width: 80px !important;
}
.d-flex {
    display: flex !important;
}
.d-inline {
    display: inline !important;
}
.flex-1{
    flex: 1 !important;
}
.flex-wrap {
    flex-wrap: wrap !important;
  }
  
  .flex-center {
    display: flex !important;
    align-items: center;
  }
  
  .flex-between {
    display: flex !important;
    justify-content: space-between;
  }
  .justify-content-between{
    justify-content: space-between !important;
}
  .justify-content-right {
    justify-content: right !important;
  }
  .justify-content-start {
    justify-content: start !important;
  }
  
  .flex-col {
    display: flex;
    flex-direction: column !important;
  }
  .align-items-start{
    align-items: start !important;
  
  }
  .align-items-center {
    align-items: center !important;
  }
  .align-items-end {
    align-items: end !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  
  .flex-start {
    align-items: flex-start !important;
  }

  .position_rel{
    position: relative !important;
  }
  .capitalize{
    text-transform:capitalize !important;
  }
.ellipse2, .ellipse4{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ellipse2{
    -webkit-line-clamp: 2;
}
.ellipse4{
    -webkit-line-clamp: 4;
}
.text_white{
    color: var(--white) !important;
}
.text-grey {
    color: var(--grey);
}
.text_black {
    color: var(--black) !important;
}
.text_primary {
    color: var(--primary) !important;
}
.text_secondary {
    color: var(--secondary-color) !important;
}
.text_blue{
    color: #5678b9 !important;
}
.btn-gradient{
    background: #0000 linear-gradient(101deg, #ee2b55, #faa72f) 0 0 no-repeat padding-box;
    border: 1px solid #ee2b55 !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}
.cancel_btn {
    background-color: #e5e5e5 !important;
    text-transform: capitalize !important;
    color: var(--black) !important;
}
.contentWraper {
    min-height: 100%;
    padding-left: 280px !important;
    padding: 30px 20px;
}

.sidebarclose .contentWraper {
    padding-left: 80px !important;

}

.sidebarclose .settings .sidebar {
    margin-left: 60px !important;

}

.sidebarclose .settings .contentWraper {
    padding-left: 340px !important;
}

.whiteContainer {
    background-color: #fff;
    border: 1px solid #E1E2E7;
    border-radius: 4px;
}

.page-heading {
    font-size: 22px !important;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
}

.Submenu-page-heading {
    font-size: 18px !important;
    font-weight: 700 !important;
}


.textfield .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #E5EBF0 !important;


}

.textfield .Mui-focused .MuiOutlinedInput-notchedOutline , .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: #E5EBF0 !important;
}

.textfield .MuiOutlinedInput-root {
    border-radius: 4px !important;
}

.textfield input {
    font-size: 13px;
    height: 25px;
    color: #333333 !important;
    padding: 5px 15px;
    border-radius: 4px !important;
}

.textfield {
    background-color: white;
    border-radius: 10px;
}
.textfield .MuiOutlinedInput-notchedOutline{
    border-color: #ddd !important;
}


.form {
    display: flex;
    flex-direction: column;
}

.form>.MuiFormControl-root {
    margin-bottom: 15px;
}
.accordion{
    margin-top: 0 !important;
}

.start_icon {
    width: 20px !important;
    height: 20px !important;
    color: #aaa;
    margin-right: 5px;
}

.star {
    color: red;
    font-size: 14px !important;
    line-height: 1 !important;
}

.textfield input::placeholder {
    opacity: 1;
}

.a-link a {
    font-size: 14px;
    cursor: pointer;
}

/* ----scrollbar---- */
html * {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: #999 #eee;
}

::-webkit-scrollbar-track {
    background: #eee
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: .4rem
}

::-webkit-scrollbar {
    height: .4rem;
    width: .4rem
}

.datatable-scroll::-webkit-scrollbar,
body::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.deleteIcon {
    color: red;
}
.mySwiper .deleteIcon , .mySwiper .deleteIcon:hover{
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: white;
    padding: 5px;
}
.deleteIcon:hover svg{
    color: red;

}

.yellowIcon {
    color: #FF9800;
}

.menuitems {
    font-size: 13px !important;
    color: #333 !important;
}

.select .MuiSelect-select {
    padding: 6px 14px !important;
    font-size: 13px;
}

.select .MuiOutlinedInput-notchedOutline {
    border-color: #ddd !important;
    border-width: 1px !important;
}

.datepicker .MuiStack-root {
    overflow: hidden;
    padding-top: 0;
}

.datepicker svg {
    color: #b7b7b7;
}

.h-100p {
    height: 100%;
}

.semibold {
    font-weight: 700 !important;
}
.fw-bold {
    font-weight: bold !important;
}
.vertical_menu{
    position: absolute !important;
    right: 3px;
    background-color: white !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 3px 5px 0px, rgba(60, 64, 67, 0.15) 0px 4px 5px 2px;
    width: 30px;
    height: 30px;
    top: 3px;
    z-index: 2;
}
.steppy_content{
    max-width: 1000px;
    margin: auto;
    padding: 50px 0;
}
.MuiSwitch-switchBase.Mui-checked{
    color: var(--primary) !important;
}
.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color:var(--primary) !important ;
}
.active_tab , .inactive_tab{
    min-width: auto !important;
    color: var(--primary) !important;
    padding: 4px 7px !important;
    border-radius: 4px !important;
    border: 1px solid #ddd !important;
}
.active_tab{
    border: 1px solid var(--primary) !important;

}
.checkbox_list{
    flex-direction: row-reverse;
    width: 100%;
    margin: 0 !important;
    justify-content: flex-end !important;
}
.theme-btn{
    background-color: var(--primary) !important;
    color: white !important;
    max-height: 34px;
    text-transform: capitalize !important;
    box-shadow: none !important;
    white-space: nowrap;
    padding: 10px 20px !important;
  }
  .textarea{
    border-color: #ddd !important;
    border-radius: 4px !important;  
    padding-inline: 10px;
    font-size: 14px;
    padding-block: 5px;
    outline: 0;
    color: #706E90;
    resize: none;
  }
  textarea{
    resize: none;
  }
  .upload_btn, .multiple_upload_btn {
    border: 1px dashed #ddd !important;
    padding: 8px 20px !important;
    border-radius: 10px !important;
    margin: 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #999 !important;
    background-color: white !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}
.uploaded_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.uploaded_img .close_icon {
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.uploaded_img {
    max-width: 200px;
    height: 180px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 10px;
    position: relative;
}
.close_icon {
    background-color: white !important;
    border-radius: 50% !important;
    width: 20px;
    height: 20px;
    min-width: 20px !important;
}
.close_icon svg {
    color: var(--black);
    font-size: 16px;
}
.multiple_upload_container {
    border: 1px dashed #ddd;
    margin: 0;
    padding: 20px;
    border-radius: 10px;
}
.multiple_upload_btn {
    max-width: 200px;
    /* height: 180px;
    width: 100%; */
    max-width: 200px;
    height: 165px;
}
.cardGrid {
    position: relative;
    height: 100%;
}
.uploaded_vdo{
    width: 100%;
    height: 100%;
    position: relative;
}
.uploaded_vdo button{
    position: absolute;
    right: 5px;
    top: 8px;
    z-index: 1;
}
.tbl_head{
     padding: 6px 16px !important;
     background-color: #F4F5F7 !important;
     color: rgba(0, 0, 0, 0.87) !important;
}
.text-center{
    text-align: center !important;
}
.text-right{
    text-align: right !important;
}
.actionbtns .icons{
    font-size: 20px;
}
.objfit_cover{
    object-fit: cover !important;
}
.del-btn{
    max-height: 34px;
    background-color: var(--grey) !important;
    text-transform: capitalize !important;
    box-shadow: none !important;
    display: inline-flex !important;
    color: var(--white) !important;
}
.benefit-btn{
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin: 0;
    padding-top: 20px;
        align-items: flex-start;
}
.my-10{
    margin: 10px 0 !important;
}
.my-30{
    margin: 30px 0 !important;
}

.autocomplete{
    width: 100%;
}
.autocomplete .MuiInputLabel-root{
    display: none;
}
/* checkbox */
.MuiCheckbox-root.Mui-checked{
    color: var(--primary) !important;
}
/* tabs */
.MuiTabs-flexContainer .Mui-selected{
    color: var(--primary) !important;

}
.MuiTabs-indicator {
    background-color: var(--primary) !important;
}
.MuiTab-textColorPrimary{
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
}
.MuiPickersDay-root.Mui-selected , .MuiClock-pmButton , .MuiClock-pin, .MuiClockPointer-root,.MuiClockPointer-thumb{
    background-color: var(--primary) !important;
}
.MuiClockPointer-thumb{
    border-color: var(--primary) !important;
}
.btnTransparent{
    background-color: transparent;
    min-width: auto !important;
}
.btnTransparent:hover{
    background-color: transparent !important;
}