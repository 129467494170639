.newmerchant{
    padding: 20px;
  }
  .newmerchant .steppy_content{
	width: 100%;
    padding: 30px;
	min-height: 60vh;
	display: flex;
    flex-direction: column;
	padding-top: 50px !IMPORTANT;
  }
  .newmerchant .steppy_content .navigate_btn{
	margin-top: auto;
	margin-left: auto;
	padding: 60px 0 20px;
  }
  .newmerchant .merchant_details{
	padding: 20px;
		align-items: center;
		padding-top: 70px;
		display: flex;
		justify-content: space-between;
		align-items: center;
  }
  .newmerchant .merchant_details img{
	width: 150px;
    height: 150px;
    object-fit: fill;
	margin-right: 10px;
  }
  .newmerchant .staff_card{
	background-color: white;
   box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    padding: 4px 15px;
    display: flex;
    align-items: center;
	border:3px solid #fff;
	position: relative;
  width: 100%;

  }
  .newmerchant .staff_card .checksvg{
	position: absolute;
    right: 1px;
    top: 2px;
    font-size: 17px;
	color: white;
}
.newmerchant .staff_card::before {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    content: "";
    border: 17px solid;
    border-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    /* z-index: -1; */
}
.newmerchant .staff_card.selected_staff::before {
	border-color: #344098 #344098 rgba(0, 0, 255, 0) rgba(255, 0, 0, 0);
}
  .newmerchant .staff_card.selected_staff{
	border:3px solid #344098;
  }
  .newmerchant .staff_card:hover{
	cursor: pointer;
  }
  .newmerchant .staff_card > div{
	word-break: break-all;
  }
  .newmerchant .staff_list{
	display: flex;
	flex-wrap: wrap;
    width: 100%;
		margin: 20px 0;
		height: 100%;
		/* justify-content: space-between; */
		gap: 20px;
  }
  
  .d-flex-center{
	display: flex;
    justify-content: center;
    align-items: center;
  }

  .step_one .individual_card img{
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .step_one{
	max-width: 100%;
	/* padding: 20px; */
  }
  .step_one .individual_card{
	background-color: white;
    border-radius: 10px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
	padding: 10px;
	position: relative;
	/* height: 100%; */
  }
  .modif_accordion{
	box-shadow: none !important;
  }
  .modif_accordion::after, 
  .modif_accordion::before{
	content: none !important;
  } 

  .modif_accordion > div{
	padding: 0;
  }
  .step_one .individual_card .checkbox{
	position: absolute;
	right: 6px;
    color: white;
  }
  .modifier_value{
	background-color: #dddddd;
    padding: 5px;
    border-radius: 4px;
    margin-right: 5px;
  }
  .modif_accordion .selected_modif{
	display: flex;

  }
  .modif_accordion .selected_modif>div{
	width: 33.3%;
  }
  .qtybtns{
    min-width: 25px !important;
    color: var(--dark-text) !important;
    font-size: 18px !important;
    height: 26px;

  }
  /* @media only screen and (max-width:1300px) {
    .newmerchant .staff_card{
      width: 100%;
      max-width: 42%;
    }
    
  } */