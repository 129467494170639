@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");

:root {
  --primary: #f3714d;
  --primary-color: #f3714d;
  --border-color: #93a4c5;
  --default-border-color: #e1e2e7;
  --font-color: #706e90;
  --border-radius: 10px;
  --sidebar-bg: #f3714d;
  --default-bg: #f4f5f7;
  --black: #333333;
  --grey:#939393;
  --white:#fff;
  --secondary-color:#FF2245;
}

* {
  font-family: "Nunito", sans-serif !important;
  /* color: #333333; */
}

/* ------login------ */

.login_img {
  height: 100vh;
  background: #0000 linear-gradient(180deg, #f89734, #f36144) 0;
  transition: left 300ms ease;
  clip-path: inset(0);
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.logintext_box {
  color: white;
  height: 100%;
  padding: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 200px;
}

.logintext_box .subheading {
  max-width: 450px;
  font-size: 20px;
  line-height: 1.5em;
  color: white;
  text-align: center;
  font-weight: 600;
}

.logintext_box h1 {
  font-weight: 400;
  font-size: 100px;
  margin: 0;
  margin-bottom: 20px;
}

.Login_content {
  padding: 0vh 10vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f7;
  justify-content: center;
}
.Login_content .header_main_left {
  text-align: center;
  padding-bottom: 50px;
}
.Login_content h4 {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 22px;
  color: #222;
}
.Login_content .textfield input{
  padding-left: 0;
  height: 35px;
}

.loginbtn {
  color: white !important;
  border: none;
  margin-top: 20px !important;
  background-color: var(--primary) !important;
  padding: 15px !important;
  border-radius: var(--border-radius) !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
}

.Login_content .a-link {
  text-align: end;
  padding-top: 15px;
}

/* ----drawer---- */
.res-menu-icon {
  display: none;
}

.res-drawer > .MuiPaper-root {
  background: #0000 linear-gradient(180deg,#f89734,#f36144) 0;
}

.res-drawer .accordion{
  width: 100%;
  background-color: transparent;
  border: none;
}
.res-drawer .accordion .MuiAccordionSummary-content{
  margin-left: 15px;
}
.res-drawer .accordion.Mui-expanded{
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.res-drawer .accordion-details .MuiButtonBase-root{
  padding: 2px 10px 2px 48px !important;
}

/* banner gradient bg -
  background: transparent linear-gradient(101deg, #FFCB5F  0%, #FFB361 20%, #FF8B65 60% , #FF6068 80% , #f3714d 100%) 0%
 */

.res-drawer .sidebar_itemText {
  color: #fff;
}

.res-drawer .MuiList-root .Mui-selected {
  background:rgba(255, 255, 255, 0.2);
}

/* .Custom_drawer .MuiList-root .Mui-selected {
    background-color: white;
    border-radius: 0 !important;
} */

.Custom_drawer .MuiList-root {
  margin: 10px;
}

.appbar {
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 3%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%) !important;
  padding-right: 20px;
  /* height: 60px; */
  height: auto;
  padding-block: 0;
  justify-content: center;
}

.appbar .logo {
  height: 60px;
  padding: 3px 0 8px 40px;
  max-width: 200px;
}
.appbar .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.appbar .toolbar {
  padding: 0 !important;
  min-height: 50px !important;
}

.Custom_drawer .MuiPaper-root {
  background-color: transparent !important;
  border: none;
  width: 100%;
  margin-top: 75px;
}
.Custom_drawer .MuiDrawer-paper {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  background: #0000 linear-gradient(180deg,#f89734,#f36144) 0;
  /* background-color: var(--primary) !important; */
  /* background: linear-gradient(180deg, rgba(20, 75, 254, 1) 30%, rgba(75, 116, 254, 1) 60%, rgba(114, 146, 254, 1) 70%, rgba(114, 146, 254, 1) 90%, rgba(202, 214, 255, 1) 100%); */
}

.sidebar-menuItem {
  padding: 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar-menuItem .MuiAccordionSummary-content {
  margin: 0;
}
.sidebarclose .sidebar-menuItem .MuiAccordionSummary-content {
  margin-left: 10px;
}
.accordion.Mui-expanded {
  background-color: #f9f9f9;
  border-radius: 4px;
}
.Custom_drawer li {
  color: #4d4d4d;
}
.Custom_drawer li > .MuiButtonBase-root {
  padding: 0 10px;
  min-height: 38px;
}
.Custom_drawer li > .MuiButtonBase-root.Mui-selected {
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 4px !important;
}
.accordion-details {
  border-top: 0 !important;
}
.accordion-details .MuiButtonBase-root {
  padding: 2px 10px 2px 42px !important;
  border-radius: 4px;
  min-height: 28px;
}
.Custom_drawer .sidebar-menuItem {
  padding: 0;
  border-radius: 4px;
  min-height: 38px;
  color: var(--white);
}
.Custom_drawer .menuicon-box {
  display: flex;
  flex-direction: row;
  min-height: 38px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
  padding: 0px 4px 0 40px !important;
  justify-content: end;
  align-items: center;
}

.sidebar_itemText span {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: var(--white);
}
.item-btn.Mui-selected .sidebar_itemText span{
  color: var(--primary);
}
.Custom_drawer .item-text span {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.sidebarIcons {
  font-size: 16px !important;
}
.listitemicon {
  min-width: 25px !important;
  color: white !important;
}
.accordion .sidebarIcons {
  padding-top: 7px;
}
.Custom_drawer li.Mui-selected {
  background-color: #ff9183;
  color: black;
  border-radius: 4px;
}
.menuicon-box h4 {
  color: var(--white);
  font-weight: bold;
}

.menuicon {
  color: var(--white);
}

.account button {
  margin-left: 10px;
}

.account button {
  padding-right: 0;
}

.account .name {
  color: #222;
}

.acct-menu .MuiMenuItem-root {
  color: #333333;
  font-size: 14px !important;
}

.menuIcon-boxClosed {
  padding-left: 14px !important;
  padding-bottom: 0;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.menuIcon-boxClosed button {
  padding-bottom: 0;
}

.css-6hp17o-MuiList-root-MuiMenu-list
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-6hp17o-MuiList-root-MuiMenu-list
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

/* ----dashboard---- */
.dashboard .cards {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
}

.dashboard .cards .card-img {
  max-width: 42px;
  margin-right: 15px;
}

.dashboard .card-content .card-value,
.dashboard .card-content .card-value span {
  font-size: 28px;
  font-weight: 700;
  margin-top: 0;
}

.dashboard .card-content .card-value span {
  line-height: 1.167 !important;
}

.dashboard .card-content .card-title {
  font-size: 14px;
  color: #89979f;
  font-weight: 600;
}

/* ----charts----- */
.chart-heading {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}

.chart {
  padding: 20px;
  position: relative;
  /* height: 500px; */
}

.echarts {
  height: 500px !important;
}

.closingcomplains .echarts {
  height: 600px !important;
}

/* ----recent activity----- */

.recent-activity-heading {
  padding: 20px;
  font-size: 18px;
  font-weight: 700;
}

.recent-activity-container {
  border-top: 1px solid var(--default-border-color);
  padding-top: 10px;
}

.activity-list-item {
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
}

.activity-list-item img {
  max-width: 38px;
  margin-right: 15px;
}

.activity-list-content .activity-desc {
  /* margin-bottom: 4px; */
  font-weight: 500;
  font-size: 13px;
}

.activity-list-content .activity-time {
  color: #89979f;
  margin-bottom: 0;
  font-size: 11px;
}

.activity-list-content .activity-desc .by {
  font-weight: 700;
  font-size: 13px;
}

.activity-list-item .complain-shortdesc {
  font-size: 13px;
}

/* -------Companies--------- */
.DT-headCell {
  font-size: 13px !important;
  color: #333333 !important;
  background-color: #f4f5f7;
  padding-block: 9px !important;
  padding-inline: 12px !important;
}

.DT-checkbox {
  background-color: #f4f5f7;
  min-width: 55px;
}

.DT-head {
  border-top: 1px solid #dddddd;
}

.DT-row {
  font-size: 13px !important;
  padding:5px 16px !important;
}

.DT-pagination p {
  margin: 0;
  padding: 0;
}

.DT-pagination .MuiToolbar-root,
.Datatable .MuiToolbar-root {
  min-height: 77px !important;
}

.Datatable .css-pjjt8i-MuiToolbar-root {
  padding-inline: 20px;
}

.DT-pagination .MuiToolbar-root {
  padding-inline: 10px;
}

.Datatable .DT-actionbtn {
  font-size: 13px;
  text-transform: capitalize;
  color: #333333;
  border: 1px solid var(--border-color);
}

.menu_popper {
  z-index: 1;
  margin-top: 5px !important;
}

.DTsearch {
  margin-left: auto;
}

.DTsearch > .MuiFormControl-root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DTsearch .label {
  margin-bottom: 0 !important;
  font-size: 13px !important;
  margin-top: 3px;
  white-space: nowrap;
}

.DTsearch .textfield .MuiOutlinedInput-root {
  border-radius: 3px !important;
}

.DTsearch .search input {
  outline: 0;
  width: 150px;
  height: 20px;
  padding: 7px 12px;
  /* padding-right: 36px; */
  font-size: 13px;
  line-height: 1.5384616;
  color: #333333;
  background-color: #fff;
  /* border: 1px solid #ddd; */
  border-radius: 3px;
}

.companies .headingBox,
.settings .headingBox,
.Addcompany .headingBox,
.Complains .headingBox,
.companyDetails .headingBox,
.notification .headingBox, .headingBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.companies .addbtn,
.settings .addbtn,
.Addcompany .addbtn,
.companies .addbtn:hover,
.settings .addbtn:hover,
.Addcompany .addbtn:hover,
.Complains .addbtn:hover,
.Complains .addbtn,
.CompDet_Adduser .addbtn,
.CompDet_Adduser .addbtn:hover,
.addbtn,
.addbtn:hover {
  max-height: 34px;
  background-color: var(--primary) !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  display: inline-flex !important;
}

.Datatable .menu .MuiMenuItem-root {
  font-size: 14px;
}

.Datatable .menu .deleteText {
  color: red;
}

.Datatable .menu .yellowText {
  color: #ff9800;
}

/* ----add companies----- */
.Addcompany .formTitle,
.CompDet_Adduser .formTitle,
.formTitle {
  font-size: 16px;
  font-weight: 700 !important;
  margin-top: 0;
  padding-left: 10px;
  color: var(--primary);
  border-left: 2px solid var(--primary);
  line-height: inherit;
  margin-bottom: 20px;
}

.Addcompany .textfield .MuiOutlinedInput-root,
.CompDet_Adduser .textfield .MuiOutlinedInput-root {
  height: 34px;
  padding: 7px 3px;
  /* padding-left: 36px; */
  border-color: #ddd !important;
  outline: 0;
  width: 100%;
  border-radius: 4px !important;
}

.Addcompany .label,
.CompDet_Adduser .label {
  font-size: 13px !important;
  font-weight: 700 !important;
}

/* -----company details----- */
.browse {
  display: inline-block;
  position: relative;
  /* //margin-right: 60px; */
  max-width: 300px;
  transition: all 300ms ease;
}

.browse-file {
  position: absolute !important;
  opacity: 0;
  z-index: 2 !important;
  left: 0;
  right: 0;
  height: 100%;
  cursor: pointer !important;
}

.logobox img {
  max-height: 100px;
  /* max-width: 240px; */
}

.help-block {
  display: block;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  color: #737373;
  font-size: 12px !important;
}

.company-info h2 {
  font-size: 18px;
  margin-top: 0;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 10px;
}

.all-details > .MuiBox-root {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  margin-bottom: 10px;
}

.all-details h6 {
  font-weight: 700;
  min-width: 120px;
  font-size: 13px;
}

.all-details > .MuiBox-root p {
  min-width: 150px;
  text-align: right;
  margin-right: 10px;
  font-size: 13px;
}

.text-danger {
  color: #ff0000 !important;
  font-weight: 700 !important;
  min-width: auto !important;
}

.companyDetails .tabs .Mui-selected {
  background-color: var(--primary);
  color: #fff !important;
  min-height: 34px;
  max-height: 34px;
  border-radius: 4px;
  text-transform: capitalize;
}

.companyDetails .tabs {
  min-height: auto;
  background-color: var(--default-bg);
  padding: 6px;
  border-radius: 8px;
}

.companyDetails .tabpanel > .MuiBox-root {
  padding: 0 !important;
}

.companyDetails .tabs .MuiTab-root {
  color: #777777;
  min-height: 34px;
  max-height: 34px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 13px !important;
  margin-right: 7px;
}

.companyDetails .logobox img {
  max-height: 150px;
}

.companyDetails .leftcontent {
  text-align: center;
}

.companyDetails .DT-head {
  border: none !important;
}

/* -----settings------ */
.sidebar {
  background: #fff;
  height: calc(100vh - 64px);
  width: 260px;
  margin-left: 260px;
  position: fixed;
  margin-top: 11px;
  overflow-x: hidden;
  border-right: 1px solid #ddd;
  z-index: 2;
}
.sidebarclose .sidebar{
  margin-left: 60px;

}
.sidebarclose .withSubmenu  .contentWraper {
  padding-left: 340px !important;
}

.Ressidebar {
  display: none;
}

.withSubmenu .contentWraper {
  padding-left: 540px !important;
}

.settings-Datatable {
  max-width: 800px;
  border: 1px solid var(--border-color);
}

.colorCell {
  display: flex;
  height: 16px;
  width: 16px;
  background: red;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.actionbtns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionbtns .edit-icon,
.actionbtns .delete-icon,
.actionbtns .delete-icon-disable,
.actionbtns .edit-icon-disable {
  font-size: 20px;
}

.actionbtns .edit-icon {
  color: #337ab7;
}

.actionbtns .edit-icon-disable {
  color: #9cc5e9;
}

.actionbtns .delete-icon {
  color: red;
}

.actionbtns .delete-icon-disable {
  color: rgb(232, 141, 141);
}

.settings .headingBox {
  max-width: 800px;
}

.addbtn-modal {
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: var(--primary) !important;
  padding-left: 0;
}

.addForm-modal .form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.addForm-modal .MuiPaper-root {
  width: 420px;
  /* position:absolute;
    top: 0; */
}

.addForm-modal .textfield input {
  height: 18px;
}

.addForm-modal .textfield .MuiOutlinedInput-root {
  padding-left: 5px;
  border-radius: 3px !important;
  /* min-width:250px; */
}

.modal-title {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #333333;
  padding-block: 20px !important;
}

.modalbtn {
  background-color: var(--primary) !important;
  font-size: 14px !important;
  color: #fff !important;
  text-transform: capitalize !important;
  height: 34px;
  padding-inline: 10px;
}

.addForm-modal .MuiDialogContent-root {
  border-top: none;
}

.addForm-modal .label {
  transform: translateY(7px);
  font-size: 13px !important;
  font-weight: 700 !important;
}

.sidebar h6,
.Ressidebar h6 {
  color: #999999;
  min-height: 30px;
  padding: 15px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.82;
  font-weight: 400;
  margin-bottom: 0;
  border-top: 1px solid #ddd;
}

.sidebar h5,
.Ressidebar h5 {
  color: #333333;
  min-height: 17px;
  padding: 15px 20px;
  border-bottom: 0;
  text-transform: uppercase;
  font-size: 11px;
}

.sidebarText {
  margin-bottom: 0 !important;
}

.sidebarText .MuiTypography-root {
  font-size: 13px !important;
  color: #333333;
  font-weight: 500;
  text-transform: lowercase !important;
}
.sidebarText .MuiTypography-root::first-letter{
  text-transform: uppercase !important;

}
.list {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
  cursor: pointer;
}

.itemIcon {
  color: #333333 !important;
}

.Res_settingsIcon {
  color: #333333 !important;
  padding-inline: 20px !important;
  font-size: 0.5rem !important;
}

.Res_AccountIcon {
  color: #333333 !important;
  font-size: 0.5rem !important;
}

.Res_AccountIcon svg {
  transform: rotate(90deg);
}

.showSettingMenu .Ressidebar {
  display: block;
  background-color: #fff;
  border-bottom: 1px solid var(--border-color);
  margin-left: 260px;
  margin-top: -4px;
  padding-block: 10px;
}

.Res_settingsIcon,
.Res_AccountIcon {
  display: none !important;
}

.sidebarclose .showSettingMenu .Ressidebar {
  margin-left: 60px;
}
.sidebarclose .item-text {
  display: none;
}
.sidebarclose .sidebar_itemText span,
.sidebarclose
  .Custom_drawer
  .sidebar-menuItem
  .MuiAccordionSummary-expandIconWrapper {
  display: none;
}

/* ----complains----- */
.Complains .table {
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.Complains .table-head {
  margin-top: 0 !important;
  padding: 14px 20px 0;
  background-color: var(--default-bg);
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.Complains .table-head > .MuiBox-root {
  margin-bottom: 10px;
}

.Complains .table-head h6 {
  color: #89979f;
  font-weight: 500;
  min-width: 120px;
  font-size: 14px;
}

.Complains .table-head .subtext {
  font-weight: 600;
  text-align: left;
  min-width: 150px;
  margin-right: 10px;
  font-size: 14px;
}

.Complains .table-head .status {
  display: inline-block;
  font-weight: 700;
  padding: 2px 5px 1px 5px;
  line-height: 1.5384616;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.1px;
  border-radius: 2px;
  /* color: #fff; */
}

.Complains .table-head .warning {
  background-color: #f0ad4e;
}

.Complains .table-head .success {
  background-color: #4caf50;
}

.Complains .table-head .danger {
  background-color: #f44336;
}

.Complains .table-content div {
  padding: 10px 20px;
  font-size: 14px;
}

.ComplainModal .MuiPaper-root {
  position: absolute;
  right: 0;
  margin: 0;
  height: 100%;
  max-height: 100%;
  border-radius: 0;
  background-color: #fff;
  /* box-shadow: 0 0 10px #ccc; */
  width: 500px;
}

.ComplainModal .heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ComplainModal .complain-heading {
  font-size: 18px;
  font-weight: 700;
}

.ComplainModal .updatebtn {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #fff;
  font-size: 14px;
  padding: 6px 8px;
  text-transform: capitalize;
  line-height: 1;
}

.ComplainModal .closebtn {
  font-size: 22px;
  color: #89979f;
  margin-left: 10px;
}

.comDetails {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 10px;
}

.comDetails .leftCont {
  color: #89979f;
  font-weight: 500;
  min-width: 120px;
  font-size: 14px;
}

.comDetails .rightCont {
  font-weight: 600;
  text-align: left;
  min-width: 150px;
  margin-right: 10px;
  font-size: 14px;
  color: #333333;
}

.comDetails .status {
  display: inline-block;
  font-weight: 700;
  border: 1px solid transparent;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.1px;
  color: #fff;
  background-color: #f0ad4e;
  border-radius: 8px;
  min-width: auto;
  padding: 2px 8px;
  text-transform: capitalize;
}

.ComplainModal .select .MuiSelect-select {
  font-size: 14px;
}

.ComplainModal .description p {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}

.ComplainModal p {
  color: #333333;
}

.ComplainModal .recent-activity-heading,
.ComplainModal .activity-list-item {
  padding-inline: 0 !important;
}

.morebtn {
  border: none !important;
  font-size: 14px !important;
  text-transform: lowercase !important;
  min-width: auto !important;
  padding: 0 !important;
  color: var(--primary) !important;
}

/* ------- */
.showAccountMenu .resAccountmenu button {
  width: 100%;
  justify-content: flex-start;
  padding-right: 10px;
  padding-top: 10px;
}

.showAccountMenu .resAccountmenu button svg {
  margin-left: auto;
}

.showAccountMenu .acct-menu .MuiPaper-root {
  width: 100%;
  left: 0 !important;
  right: 0;
  top: 116px !important;
  box-shadow: none;
  filter: none;
  max-width: 100%;
  transition: none !important;
  padding-inline: 20px;
  box-shadow: 0 0.46875rem 2.1875rem rgb(8 10 37 / 0%),
    0 0.9375rem 1.40625rem rgb(8 10 37 / 3%),
    0 0.25rem 0.53125rem rgb(8 10 37 / 5%),
    0 0.125rem 0.1875rem rgb(8 10 37 / 3%) !important;
}

.showAccountMenu .contentWraper {
  padding-top: 60px;
}

.showAccountMenu .acct-menu .MuiMenuItem-root {
  padding-block: 8px;
}

/* .resAccountmenu{
    display: none;
} */
.dashboard .compCountGrid {
  /* flex-basis:20%; */
  min-width: 270px;
}

.settings-Datatable .toolbar {
  background-color: transparent !important;
  padding-right: 15px;
}

.css-1q1u3t4-MuiTableRow-root.Mui-selected {
  background-color: transparent !important;
}

.adduserOption p {
  font-weight: 800;
}

.dashboard .calendarBox {
  display: flex;
  align-items: center;
  margin-right: 10px;
  flex-wrap: wrap;
}

.dashboard .calendarBox > .flex-box {
  margin-bottom: 10px;
}

.dashboard .calendarBox .datepicker .MuiInputBase-root {
  height: 40px;
  max-width: 150px;
  width: 100%;
}

.dashboard .chart .flexContainer {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}

.dashboard .chart .heading {
  max-width: 100px;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.swiper {
  width: 100%;
  height: 100%;
  max-width: 380px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 100% !important;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 16px !important;
  color: var(--primary);
  font-weight: 700;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #ddd;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  top: 59% !important;
}

.swiper-button-prev {
  left: 0 !important;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.audioMb {
  margin-bottom: -20px;
}

.uploadicon.liked {
  color: var(--primary);
}

.thumbsUp {
  font-size: 30px !important;
}

.notificationList h5 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.notification .description {
  font-size: 16px;
  color: #333;
}

.notification-time {
  margin-top: 10px !important;
}

.notification .avatarGrp {
  padding-left: 0;
  padding-block: 5px;
  padding-top: 10px;
  margin-block: 0;
}

.notificationList ul,
.notificationList ul li {
  padding: 0;
}

.notificationList ul li {
  margin-block: 20px;
}

.notificationList .MuiDivider-inset {
  padding: 0;
}

.notificationList ul:hover,
.notificationicon:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 5%), 0 2px 20px 0 rgb(0 0 0 / 11%);
}

.notificationList ul {
  padding-inline: 20px;
  padding-top: 1px;
}

.appbar .toolbar .icons {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.multiple_uploadbtn {
  width: 100px;
  height: 100px;
  margin-bottom: 0;
}

.multiple_uploadbtn > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}

.Addcompany .uploadedImgs > div {
  margin-right: 10px;
  margin-block: 5px;
  max-width: 100px;
  width: 100% !important;
}

.Addcompany .uploadedImgs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Addcompany .uploadedVideos > div {
  margin-right: 10px;
  margin-block: 10px;
  max-width: 300px;
}

/* add role */
.addRoleForm_modal .flex-col-rev {
  display: flex;
  /* flex-direction: column-reverse; */
  align-items: center;
  padding-right: 30px;
  margin-bottom: 0;
}

.addRoleForm_modal .flex-col-rev > span {
  padding-left: 0;
  /* padding-bottom: 0; */
}

.addRoleForm_modal .MuiPaper-elevation {
  max-width: 600px;
  width: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
}

.addRoleForm_modal .eachmodule {
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
}

.addRoleForm_modal .eachmodule h6 {
  font-size: 14px;
  font-weight: 700;
}

.addRoleForm_modal .eachmodule .flex-center {
  padding: 10px 10px 10px 14px;
  flex-wrap: wrap;
}

.pt-10 {
  padding-top: 10px !important;
}

.picker .swatch {
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* NEWD CSSS FROM  YOGA FRONTEND PROJECT*/

.header_main_in {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auth_page .header_main_right {
  display: flex;
}

.auth_page .auth_form {
  max-width: 900px;
  margin-top: -300px;
  margin-bottom: 90px;
}

.auth_page form .theme_btn {
  max-width: 350px;
  width: 100%;
  background: transparent linear-gradient(101deg, #f3714d 0%, #faa72f 100%) 0%
    0% no-repeat padding-box;
  color: var(--white) !important;
  padding: 10px 30px !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.auth_page .theme_btn {
  background: var(--white);
  color: var(--primary-color) !important;
  max-width: 200px;
  width: 100%;
}
.fanTimeline {
  max-width: 800px;
  margin: auto;
}
.fanTimeline .timeline_box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px;
  text-align: left;
  background-color: white;
    margin-bottom: 20px;
}
.fanTimeline .comments_section{
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.community_details .video_banner video {
  max-height: 600px;
  object-fit: cover;
  border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.community_details .video_banner {
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.community_details .video_banner .playbtn {
  position: absolute;
  top: 42%;
  left: 0;
  right: 0;
  margin: auto;
  width: 60px !important;
  height: 60px;
  border-radius: 50%;
}
.communityDet_Images .deleteIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  padding: 3px;
}
/* Footer */
.footer_main {
  background: url(./../images/footerbg.png) no-repeat;
  width: 100%;
  height: 700px;
  color: var(--white);
  font-size: 14px;
  background-size: cover;
}

.footer_main_in {
  padding: 80px 20px;
}

.socialmedia_icons i,
.socialmedia_icons svg {
  color: var(--white);
  font-size: 20px;
  padding-right: 30px;
  padding: 30px 30px 30px 0;
}

.footer_main_center > div {
  color: white;
  padding: 0 60px;
}

.footer_main_center {
  border-left: 1px dashed #eee;
  border-right: 1px dashed #eee;
}

.footer_main_left {
  padding-right: 60px;
}

.footer_main_right {
  padding-left: 60px;
}

.footer_main_in > .MuiGrid-container {
  flex-wrap: nowrap;
}

.socialmedia_icons i,
.socialmedia_icons svg {
  color: var(--white);
  font-size: 20px;
  padding-right: 30px;
  padding: 30px 30px 30px 0;
}
.item-btn.Mui-selected {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
.Custom_drawer li .Mui-expanded.accordion{
  background-color: rgba(255, 255, 255, 0.2) !important;

}
.podcastDetails .custom_card {
  margin-top: 50px;
}
.upload-img .close{
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  min-width: 30px;
  border-radius: 50%;
  height: 30px;
}

.podcastDetails .bannerContent img {
  border-radius: 8px;
  height: 100%;
  /* max-height: 445px; */
  object-fit: cover;
  width: 100%;
}

.podcastDetails .themeGradient_bg > .container {
  transform: translateY(-200px);
}

.podcastDetails .themeGradient_bg {
  margin-top: 300px;
}

.podcastDetails .playbtn {
  top: 35%;
}

.podcastDetails video {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

.audio_player {
  border-radius: 10px;
  box-shadow: none !important;
  max-width: 600px;
  padding: 40px 30px !important;
}

.rhap_main-controls-button,
.rhap_repeat-button,
.rhap_time,
.rhap_volume-button {
  color: var(--primary-color) !important;
}

.rhap_progress-indicator,
.rhap_volume-indicator {
  background: var(--primary-color) !important;
}

.rhap_progress-filled {
  background-color: var(--primary-color) !important;
}

.rhap_play-pause-button {
  color: var(--primary-color) !important;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 20px !important;
}

.audioPlayer_container {
  background-color: #f1f1f1;
  margin-top: auto;
  padding: 50px 40px;
}

.podcastDetails .Podcontent {
  margin: auto 0;
  padding: 40px;
}
/* profile */
.profile-photo {
  border: 1px dashed rgba(145, 158, 171, 0.32);
  border-radius: 50%;
  height: 144px;
  margin: auto;
  padding: 8px;
  position: relative;
  width: 144px;
}

.profile-photo .MuiAvatar-circular,
.profile-photo span.MuiIconButton-root {
  height: calc(100% - 8px);
  left: 4px;
  position: absolute;
  top: 4px;
  width: calc(100% - 8px);
}

.profile-photo svg {
  position: relative;
  transition: all 0.3s linear;
  visibility: visible;
}

.profile-photo svg path {
  fill: var(--highLightDark);
}

/* .personal-info-tab {
  height: 100%;
} */

.personal-info-tab .MuiInputLabel-formControl {
  color: #919eab;
}
.personal-info-tab .MuiOutlinedInput-notchedOutline {
  border-color: rgba(145, 158, 171, 0.32);
}
.profUploadbtn {
    display: none !important;
}
.personal-info-tab .profile-heading{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}
.allow-filetypes {
    color: #333;
    font-size: 12px !important;
    margin-top: 20px !important;
    text-align: center;
}
.custom_modal .MuiDialogActions-root{
  padding: 10px 20px 15px !important;
}
.custom_modal .MuiDialogActions-root button{
  padding: 2px 8px !important;
  min-width: 52px;
}
.audioPlayer_cont .rhap_container{
  box-shadow: none;
}
.reply-button:hover{
  cursor: pointer;
}
.community_details > .container{
  max-width: 1000px;
  margin:40px auto;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.community_details .receiver {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px 20px;
}
.badge > span{
  background-color: var(--primary);
  color: var(--white);
}
.commentIcons{
  position: relative;
  background-color: #f7f7f7 !important;
  padding: 4px 10px !important;
}
.commentIcons .count{
  position: absolute;
  top: 0;
  left: 24px;
  color: var(--white);
  background-color: var(--primary);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 9px !important;
  /* padding-top: 2px; */
  display: flex;
  align-items: center;
  justify-content: center;
} 
.commentIcons span{
  text-transform: capitalize;
}
/* videos */
.thumbnail-video-horizontal-item .videoContainer {
  position: relative;
  width:200px;
  height: 150px;
  overflow: hidden;
  margin-right: 10px;
}
.thumbnail-video-horizontal-item .videoContainer video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .addNews */
 .multiple_upload_btn{
  width: 68%;
}
/* select inside Textfield */
.InnerSelect .MuiSelect-select{
  padding: 5px 14px 5px 0;
}
.InnerSelect fieldset{
  border: none;
}
.notificationModal .notif_card{
  background-color: #F69535;
  border-radius: 14px;
}
.notificationModal .notif_card .headBox{
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px;
}
.imageContainer.position_rel{
  height: 250px;
}
.imageContainer .deleteIcon {
  position: absolute;
  right:5px;
  top: 5px;
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 0 3px 5px 0 #3c40434d, 0 4px 5px 2px #3c404326;
}

/* comments */
.comment_each {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.receiver  .comment_box,.sender  .comment_box{
  box-shadow: none;
  border-top: 1px solid #dfdfdf;
  padding: 20px 0px 20px;
  margin-top: 10px;
  align-items: center;
  position: relative;
}
.receiver  .comment_box .cancel_btn,.sender  .comment_box .cancel_btn{
  max-height: 35px;
  font-size: 14px;
}

.comment_each img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

.comments_section .receiver {
  /* border: 1px solid #f89634; */
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  background-color: var(--white);
  margin: 10px auto 10px 10px;
  position: relative;
}

.comments_section .receiver_grid {
  border-left: 1px solid #ef355259;
  margin-left: auto;
  margin-top: 30px;
  padding-top: 0 !important;
  max-width: 95%;
}
.comments_section .sender {
  max-width: 80%;
  margin: 10px 0px 10px auto;
  background-color: var(--white);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  /* border: 1px solid #ef355259; */
}
.comments_section .doubleReply_box  .sender{
  max-width: 70%;

}
.comments_section .content {
  opacity: 0;
  transition: opacity 0.5s ease, max-height 0.5s ease;
  max-height: 0;
  overflow: hidden;
}

.comments_section .content.show {
  opacity: 1;
  max-height: 1000px; /* Adjust based on your content */
}
.comments_section .content.show .sender{
  max-width: 70%;
  margin: 0px 0px 10px auto;
}

.comments_section .content.hide {
  opacity: 0;
  max-height: 0;
}

.comments_section .sender::after {
  content: "";
  position: absolute;
  height: 107%;
  width: 1px;
  left: -40px;
  top: 0;
  background-color: #ddd;
}

.comments_section {
  background-color: #f5f6fa;
}

.comments_section .doubleReply_box::after {
  /* content: ""; */
  position: absolute;
  height: 100%;
  width: 1px;
  left: 50px;
  top: 0;
  background-color: #ddd;
}
.comments_section .doubleReply_box {
  position: relative;
}
.comments_section .doubleReply_box .receiver {
  max-width: 80%;
}
.comments_section .doubleReply_box .receiver::after {
  content: "";
  position: absolute;
  height: 107%;
  width: 1px;
  left: -50px;
  top: 0;
  background-color: #ddd;
}
.comments_section .collapseBtns button {
  background-color: #f5f6fa;
  min-width: 35px;
  max-height: 35px;
}
.comments_section .collapseBtns {
  margin-right: 20px;
  background-color: #f5f6fa;
  border-radius: 4px;
  min-width: 35px;
}

.comments_section .collapseBtns p {
  text-align: center;
  padding: 0px 10px;
  font-weight: 500;
}
.comments_section .badge{
  background-color: var(--primary);
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0 10px;
}
.comment_box {
  display: flex;
  align-items: flex-start;
  padding: 30px 20px 15px;
  background-color: white;
  position: sticky;
  bottom: -17px;
  box-shadow: 0px -25px 27px -25px rgba(224,224,224,1);
}
.comment_box .textfield .MuiOutlinedInput-notchedOutline {
  box-shadow: none;
  border-radius: 8px;
}
.comment_box .textfield .MuiOutlinedInput-root{
  padding: 6px 10px;
}

.comment_box .textfield {
  padding: 0 10px 0 0;
}

.comment_box img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.comments_section > .MuiGrid-container {
  max-height: 60vh;
  overflow: auto;
  padding-bottom: 20px;
  padding-right: 15px;
  border-radius: 14px;
}
.commentModal .MuiDialogContent-root{
  overflow-y: hidden;
}

/* / plan page / */
.plan_card {
  padding: 30px;
  border-radius: 20px;
  border: 2px solid #fbb680;
  background-color: #ffcca3;
  min-height: 450px;
  transition: transform .2s;
  position: relative;
  width: 100%;
}
.planModal .MuiGrid-root.MuiGrid-item {
  display: flex;
}
.planModal h5 {
  text-transform: uppercase;
  word-break: break-word;
}
.planModal .ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
}
.planModal .ribbon span {
  position: absolute;
  display: block;
  width: 240px;
  padding: 0px 0;
  background: transparent linear-gradient(101deg, #ee2b55 30%, #faa72f 100%) 0%
    0% no-repeat padding-box;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
  left: -15px;
  top: 30px;
  transform: rotate(45deg);
}


/* .planModal .ribbon {
  position: fixed;
  background: transparent linear-gradient(101deg, #ee2b55 30%, #faa72f 100%) 0%
  0% no-repeat padding-box;
  clip-path: inset(0 -100%);
  inset: 0 0 auto auto;
    transform-origin: 0 0;
    transform: translate(29.3%) rotate(45deg);
} */
.planModal .swiper-slide-active {
  transform: scale(1.1) !important;
  position: relative;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 30px;
}
.planModal .swiper-slide-active .plan_card {
  background-color: #ffe8d5;
}
.planModal .mySwiper {
  padding: 30px 0px !important;
}
.theme_modal.planModal .MuiPaper-root {
  width: 100%;
  border-radius: 20px;
  padding: 0px 30px 10px;
}
.theme_modal.planModal .swiper-wrapper {
  align-items: center;
  padding: 10px 0;
}
.planModal .withoutSlider .plan_card:hover {
  background-color: #ffe8d5;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1.05);
  cursor: pointer;
}
.planModal .MuiDialogContent-root {
  min-height: 73vh;
}
.calendarPicker > .MuiStack-root{
  max-width: 185px;
}
.calendarPicker .textfield{
  min-width: auto !important;
}
.course.firstScreen .leftContent {
  max-width: 60%;
  margin: auto;
  padding: 100px 30px !important;
}
.course.firstScreen{
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course.firstScreen > div{
  min-height: 80vh;

}
.course.firstScreen .rightContent_grid{
  background-color: #ffe5d1;
}
.course.firstScreen .rightContent_grid > div{
  max-width: 90%;
}
.course.firstScreen .rightContent_grid .MuiPaper-root , .course.firstScreen .rightContent_grid .MuiPaper-root img{
  border-radius: 8px;
}
.courseDetail_page .bg_secondaryColor{
  padding: 2px 10px;
  border-radius: 4px;
}
.curriculumPage .eachcard{
  border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}
.curriculumPage .eachcard.active{
  background-color: oldlace;
}
.curriculumPage .eachcard.active span{
  font-weight: 800;
}
.curriculumPage .MuiAccordionSummary-root{
  min-height: 45px;
}
.curriculumPage .MuiAccordionSummary-content{
  margin: 10px 0;
}
.curriculumPage .course_mode{
    color: white !important;
    padding: 2px 5px;
    border-radius: 8px;
}
.curriculumPage .course_mode.online{
  background-color: #00ad00;
}
.curriculumPage .chapterlist{
  max-height: 75vh;
  overflow-y: scroll;
  position: sticky;
  top: 100px;
}
.courseTabs > div{
  padding: 10px 0;
}
.curriculumPage  .addChapter_btn{
  position: sticky;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: auto;
  margin: auto;
  text-align: center;
  background-color: white;
  padding:10px 0;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.course  .textfield .MuiOutlinedInput-notchedOutline,
.course .textarea{
  /* border-color: #000 !important; */
}
/* dashboard */
.dashboard .individual_table{
  background-color: #fff;
  border: 1px solid #AAAAAA;
  border-radius: 10px;
}
.dashboard .individual_table hr{
  border-color: #AAAAAA;

}
.dashboard .countBox {
  align-items: center;
    background-color: var(--primary);
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-weight: 700;
    height: 50px;
    justify-content: center;
    width: 70px;
    font-size: 24px;
}
.dashboard .individual_table h6, .dashboard .individual_table td{
  letter-spacing: 2px;
  font-weight: 600;
}
.dashboard .individual_table td, .dashboard .individual_table td > div{
 font-size: 15px !important;
}
.dashboard .table_content th{
  border-bottom-width: 1px !important;
  color: #000 !important;
  font-weight: bold !important;
  vertical-align: middle !important;
  font-size: 16px;
  letter-spacing: 2px;
}
.dashboard .table_content tr {
  background-color: initial !important;
}

.drip_schedule .MuiListItem-root{
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;

}
.drip_schedule .chapterlist{
  min-height: 70vh;
}

/* pricingPage */
.pricingPage .card{
  max-width: 600px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.pricingPage .priceCard{
  background-color: #eef0f5;
  border-radius: 10px;
}
.modetype{
    display: inline;
    background-color: green;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px !important;
    color: white;
}
.rdw-editor-wrapper{
  min-height: 500px;
}
.quizPage.whiteContainer, .quizPage .whiteContainer{
  min-height: 65vh;
}
.quizPage .add_btn{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  background-color: white;
  padding: 10px;

}